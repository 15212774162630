<template>
  <div :class="$style.container">
    <form @submit.prevent="submitForm">
      <h1>Редактировать рекламное уведомление</h1>
      <Input v-model="notification.title" placeholder="Заголовок" />
      <div v-if="this.errors.title" :class="$style.error">
        {{ this.errors.title }}
      </div>
      <div :class="$style.imageBlock">
        <ImageBulkUploader
          v-model="notification.image"
          :text="'Загрузить фото'"
          :class="$style.imageUploader"
        />
        <div :class="$style.photo">
          <img :src="s3_link + notification.image[0]" alt="Загрузить фото" />
        </div>
      </div>
      <div v-if="this.errors.image" :class="$style.error">
        {{ this.errors.image }}
      </div>
      <div :class="$style.date">
        <label>
          Начало показа уведомления
          <input type="date" v-model="notification.startAt" />
          <br />
          <div v-if="this.errors.startAt" :class="$style.error">
            {{ this.errors.startAt }}
          </div>
        </label>
        <label>
          Конец показа уведомления
          <input type="date" v-model="notification.endAt" />
          <br />
          <div v-if="this.errors.endAt" :class="$style.error">
            {{ this.errors.endAt }}
          </div>
        </label>
      </div>
      <Input
        v-model="notification.description"
        textarea
        placeholder="Описание уведомления"
      />
      <div v-if="this.errors.description" :class="$style.error">
        {{ this.errors.description }}
      </div>
      <Input
        v-model="notification.attachmentLink"
        placeholder="Дополнительная ссылка"
      />
      <h5>
        Если ценовая политика не указана, то рекламное уведомление отображается
        для всех пользователей
      </h5>
      <el-select
        v-model="notification.pricePolicies"
        multiple
        filterable
        default-first-option
        placeholder="Выберите ценовые политики"
      >
        <el-option
          v-for="item in pricePoliciesOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <div :class="$style.bonusProgramsInfoWrapper">
        <h5>
          Выберите бонусные программы, которые будут показываться маркетинговому
          уведомлению
        </h5>
        <div :class="$style.selectsWrapper">
          <el-select
            v-model="bonusProgramStatus"
            placeholder="Выбрать"
            clearable
            :class="$style.select"
            @change="getBonusProgramList"
          >
            <el-option
              v-for="(item, index) in bonusProgramSelectOptions"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="selectedBonusPrograms"
            value-key="id"
            filterable
            multiple
            default-first-option
            placeholder="Выбрать"
            clearable
            :class="$style.select"
          >
            <el-option
              v-for="item in bonusPrograms"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <Checkbox v-model="notification.isNews"> В новостную ленту </Checkbox>
      <Checkbox v-model="notification.forSuppliers">
        Для поставщиков не участвующих в бонусной программе
      </Checkbox>
      <Checkbox v-model="notification.forDealers">
        Для дилеров не участвующих в бонусной программе
      </Checkbox>
      <Checkbox v-model="notification.forDealersBonusProgramMembers">
        Для дилеров участников бонусной программы
      </Checkbox>
      <Checkbox v-model="notification.forSuppliersBonusProgramMembers">
        Для поставщиков участников бонусной программы
      </Checkbox>
      <span :class="$style.marketingNotificationDisplayHelper">{{
        marketingNotificationDisplayHelper
      }}</span>
      <br />
      <div v-if="this.errors.attachmentLink" :class="$style.error">
        {{ this.errors.attachmentLink }}
      </div>
      <div :class="$style.action">
        <Button type="submit">Изменить уведомление</Button>
        <div v-if="error" :class="$style.error">
          {{ error }}
        </div>
      </div>
      <Loader :isLoading="isLoading" />
    </form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import { formatInputDate } from '@/helpers'
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import {
  BONUS_PROGRAM_STATUSES,
  BONUS_PROGRAM_STATUSES_DISPLAY,
} from '@/constants/bonusProgram'
import { marketingNotificationDisplayHelper } from './helpers/index'

import Button from '@/components/atoms/Button.vue'
import Input from '@/components/atoms/Input.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'
import ImageBulkUploader from '@/components/moleculs/ImageBulkUploader'

export default {
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [notifications],
  components: {
    ImageBulkUploader,
    Button,
    Loader,
    Input,
    Checkbox,
  },
  computed: {
    s3_link() {
      return process.env.VUE_APP_S3_URL
    },
    marketingNotificationDisplayHelper() {
      return marketingNotificationDisplayHelper(
        this.notification.isNews,
        this.notification.forDealers,
        this.notification.forSuppliers,
        this.notification.forDealersBonusProgramMembers,
        this.notification.forSuppliersBonusProgramMembers,
      )
    },
  },
  watch: {
    async selectedBonusPrograms() {
      if (!this.selectedBonusPrograms.length) {
        await this.getBonusProgramList()
      }
    },
  },
  data() {
    return {
      selectedBonusPrograms: [],
      bonusPrograms: [],
      bonusProgramStatus: BONUS_PROGRAM_STATUSES.ACTIVE,
      pricePoliciesOptions: [],
      notification: {
        title: '',
        description: '',
        image: '',
        startAt: '',
        endAt: '',
        attachmentLink: '',
        isNews: false,
        forSuppliers: false,
        forDealers: false,
        forDealersBonusProgramMembers: false,
        forSuppliersBonusProgramMembers: false,
        pricePolicies: [],
      },
      errors: {
        title: '',
        description: '',
        image: '',
        startAt: '',
        endAt: '',
        attachmentLink: '',
      },
      error: '',
      minDate: formatInputDate(new Date(Date.now() + 86400000)),
      isLoading: false,
      bonusProgramSelectOptions: [
        {
          name: BONUS_PROGRAM_STATUSES_DISPLAY.ACTIVE,
          value: BONUS_PROGRAM_STATUSES.ACTIVE,
        },
        {
          name: BONUS_PROGRAM_STATUSES_DISPLAY.INACTIVE,
          value: BONUS_PROGRAM_STATUSES.INACTIVE,
        },
      ],
    }
  },
  async created() {
    await this.getMarketingNotification()
    await this.getBonusProgramList()
  },
  mounted() {
    this.getPricesPolicies()
  },
  methods: {
    async getPricesPolicies() {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.AddwineCore.PricePolicyActions.getList({
          limit: 100,
          page: 1,
        })
      loading.close()
      if (!error) {
        this.pricePoliciesOptions = value.data
      }
    },
    clearErros() {
      this.error = ''
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },
    isFormValid() {
      let isValid = true
      this.clearErros()
      if (!this.notification.title) {
        this.errors.title = 'Введите заголовок'
        isValid = false
      }
      if (!this.notification.description) {
        this.errors.description = 'Добавьте текст'
        isValid = false
      }
      if (!this.notification.image) {
        this.errors.image = 'Добавьте картинку'
        isValid = false
      }
      if (!this.notification.startAt) {
        this.errors.startAt = 'Добавьте дату начала акции'
        isValid = false
      }
      if (!this.notification.endAt) {
        this.errors.endAt = 'Добавьте дату окончания акции'
        isValid = false
      }
      if (
        new Date(this.notification.startAt) > new Date(this.notification.endAt)
      ) {
        this.errors.startAt =
          'Неправильно введена дата: начальная больше, чем конечная'
        isValid = false
      }
      if (
        this.notification.attachmentLink &&
        !regexp.url.test(this.notification.attachmentLink)
      ) {
        this.errors.attachmentLink = 'Введите валидный url'
        isValid = false
      }

      if (!isValid) {
        this.error = 'Заполните все поля или введите корркетные данные'
      }

      return isValid
    },
    async submitForm() {
      this.clearErros()
      if (this.isFormValid()) {
        this.isLoading = true

        const bonusProgramIds = this.selectedBonusPrograms.map(
          (item) => item.id,
        )

        const data = {
          ...this.notification,
          image: this.notification.image[0],
          startAt: new Date(this.notification.startAt),
          endAt: new Date(this.notification.endAt),
          bonusPrograms: bonusProgramIds,
        }

        const { error } =
          await delivery.AddwineCore.MarketingNotificationActions.update(
            this.$route.params.id,
            data,
          )

        this.isLoading = false

        if (error) {
          this.error = error
          this.showNotification(
            'Произошла ошибка редактирования маркетингового уведомления',
            'error',
          )
          return
        }

        this.$router.push(ADDSELLERS_ADMIN_ROUTES.MARKETING_NOTIFICATIONS.LIST)

        this.showNotification(
          'Маркетинговое уведомление успешно изменено',
          'success',
        )
      }
    },
    async getMarketingNotification() {
      const { value, error } =
        await delivery.AddwineCore.MarketingNotificationActions.getById(
          this.$route.params.id,
        )

      if (error) {
        return
      }

      this.notification = {
        ...value,
        startAt: formatInputDate(value.startAt),
        endAt: formatInputDate(value.endAt),
        image: [value.image],
      }

      this.selectedBonusPrograms = value.bonusPrograms
    },
    async getBonusProgramList() {
      const query = {
        limit: 100,
        page: 1,
        orderBy: 'starts_at',
        orderDir: 'desc',
        status: this.bonusProgramStatus,
      }

      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.BonusProgramActions.getList(query)

      loading.close()

      if (error) return

      this.selectedBonusPrograms.forEach((item) => {
        value.data = value?.data.filter((data) => item.id !== data.id)
      })

      this.bonusPrograms = [...this.selectedBonusPrograms, ...value.data]
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.container {
  max-width: 58.5rem;
  margin: 0 auto;
  padding: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    label {
      text-align: left;
    }
  }
  .title {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
  }
  .imageBlock {
    display: flex;
    justify-content: space-between;
    .imageUploader {
      display: flex;
      align-items: center;
      text-align: left;
      color: $black-gray;
      padding: 0.5rem;
      border: 0.063rem solid $black-gray;
      max-width: 12.5rem;
    }
    .photo {
      width: 7.5rem;
      height: 7.5rem;
      border: 1px solid $black-gray;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .date {
    display: flex;
    gap: 1rem;
    label {
      color: $black-gray;
    }
  }
  .lableUrl {
    color: $black-gray;
    text-align: left;
  }
  .url {
    width: 100%;
    padding: 0.5rem;
  }
  .error {
    @include errorInscription;
    margin-top: 0.4rem;
  }

  .bonusProgramsInfoWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;

    .selectsWrapper {
      display: flex;
      gap: 3rem;
      .select {
        width: 30rem;
      }
    }
  }

  .marketingNotificationDisplayHelper {
    @include H200;
    margin-top: 1rem;
  }
}
</style>
